<template>
  <div class="lightbox-subscription-expired">

    <template v-if="loading">
      <img class="spinner" src="../../assets/images/spinner.svg" />
      <p class="loading-text">{{ loadingText }}</p>
    </template>

    <template v-else>
      <!-- Package Selection -->
      <template v-if="view === paymentViews.packages">
        <div class="subtitle" v-html="subTitle"></div>
        <div class="products">
          <template v-for="product in subscriptionProducts">
            <div v-if="product.active" :key="product.id" :class="productClasses(product)">
              <h3>{{ product.name }}</h3>

              <p class="price" v-html="parsePrice(product.price)"></p>

              <div class="divider"></div>

              <ul class="features">
                <li v-for="(feature, index) in features(product.features)" :key="index">
                  <font-awesome-icon :icon="['fas', 'check']" />{{ feature }}
                </li>
              </ul>

              <MercalaButton
                v-on:button-event="selectProduct(product)"
                :label="productButtonLabel(product)"
                type="dashboard-ok"
              />
            </div>
          </template>
        </div>
      </template>

      <!-- Payment Methods -->
      <!-- Disabled for now, keep for future references --> 
      <!-- <template v-if="view === paymentViews.paymentMethods">
        <div class="payment-selection">
          <div class="product">
            <h3>{{ selectedProduct.name }}</h3>

              <p class="price" v-html="parsePrice(selectedProduct.price)"></p>

              <div class="divider"></div>

              <ul class="features">
                <li v-for="(feature, index) in features(selectedProduct.features)" :key="index">
                  <font-awesome-icon :icon="['fas', 'check']" />{{ feature }}
                </li>
              </ul>
          </div>
          <div class="payment-methods">
            <h2>Select your payment method</h2>
            <div @click="goToCheckout()" class="method is-third-party">
              <div class="method-info">
                <h3>Credit Card, Apple Pay or Google Pay</h3>
                <font-awesome-icon :icon="['fa', 'angle-right']" />
              </div>
            </div>
            <div class="method is-bank-transfer">
              <div class="method-info">
                <h3>Bank transfer</h3>

                <p>Please contact sales for further instructions:</p>

                <p><b>Email:</b> <a href="mailto:info@mercala.org">info@mercala.org</a></p>

                <p><b>Phone:</b> (+297) 564-7578</p>
              </div>
            </div>
          </div>
        </div>
      </template> -->

      <!-- Checkout -->
      
       <div class="payment-tools">

        <p>Access is granted upon receipt of payment. For questions contact Support at  <a href="mailto:info@mercala.org">info@mercala.org</a></p>

        <!-- Disabled for now, keep for future references --> 
        <!-- <MercalaButton
          v-if="view === paymentViews.paymentMethods"
          v-on:button-event="goToView(false)"
          extra-classes="button-back"
          label="Back"
          type="dashboard-cancel"/>

        <MercalaButton
          v-if="view === paymentViews.packages"
          v-on:button-event="goToView(true)"
          extra-classes="button-next"
          label="Next"
          type="dashboard-ok"/> -->
      </div>
    </template>
  </div>
</template>

<script>
import AngelaAPI from "@/utils/AngelaAPI";
import MercalaButton from "@/components/form/MercalaButton";
// import { payawPaymentCheckout } from "@/utils/payaw";

export default {
  name: "LightboxSubscriptionExpired",
  components: {
    MercalaButton,
  },
  props: {
    refreshText: {
      type: Boolean,
      default: true,
    },
    lightboxData: {
      type: Object,
      default: () => {
        return {
          status: "",
          subscriptionType: "",
        };
      },
    },
  },
  computed: {
    subTitle() {
      let subTiitleText = "";

      if (this.dashboardSettings) {
        if (this.lightboxData.subscriptionType === "trial") {
          subTiitleText = this.dashboardSettings.trial_expired_text;
        } else {
          subTiitleText = this.dashboardSettings.subscription_expired_text;
        }
      }

      return subTiitleText;
    },
  },
  data() {
    return {
      loading: true,
      loadingText: "Getting new subscriptions",
      paymentViews: {
        packages: 0,
        paymentMethods: 1,
      },
      dashboardSettings: null,
      subscriptionProducts: [],
      selectedProduct: null,
      view: 0,
    };
  },
  methods: {
    getDashboardSettings() {
      this.$store.getters
        .getDashboardSettings(this.refreshText)
        .then((settings) => {
          this.dashboardSettings = settings;
        })
        .catch((error) => {
          // Nothing to handle
        });
    },
    getSubscriptionProducts() {
      AngelaAPI.getSubscriptionProducts()
        .then((response) => {
          if (response.status === 200) {
            // Add and sort products
            this.subscriptionProducts = response.data.data.products.sort(
              (pa, pb) => {
                const orderA = parseInt(pa.order);
                const orderB = parseInt(pb.order);

                if (orderA > orderB) {
                  return 1;
                } else {
                  return -1;
                }
              }
            );

            // Set selected product
            const selected = this.subscriptionProducts.find((product) => {
              return product.selected === "true";
            });

            if (selected) {
              this.selectedProduct = selected;
            }

            this.loading = false;
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    features(features) {
      return features.split("|");
    },
    parsePrice(price) {
      let currencyIcon = "";

      switch (price.currency) {
        case "eur":
          currencyIcon = "&euro;";
          break;
        case "usd":
          currencyIcon = "&dollar;";
          break;
        case "awg":
          currencyIcon = "awg";
          break;
      }

      return `${currencyIcon}${price.amount}`;
    },
    selectProduct(product) {
      this.selectedProduct = product;
      this.goToCheckout();
    },
    // goToView(isNext) {
    //   if (isNext) {
    //     this.view++;
    //   } else {
    //     this.view--;
    //   }
    // },
    goToCheckout() {
      this.loadingText = "Preparing for checkout";
      this.loading = true;
      AngelaAPI.createPayment(this.selectedProduct.id)
        .then((response) => {
          if (response.status === 200) {
            location.href = response.data.data.redirectUrl;
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    setTitle() {
      let title = "";

      if (this.lightboxData.subscriptionType === "trial") {
        title = "Become a Member";
      } else {
        title = "New Subscription Plan";
      }

      this.$emit("setLightboxInfo", {
        title,
      });
    },
    productButtonLabel(product) {
      // if (this.selectedProduct && this.selectedProduct.id === product.id) {
      //   return "selected";
      // }
      return "select";
    },
    productClasses(product) {
      return {
        product: true,
        // selected: this.selectedProduct && this.selectedProduct.id === product.id,
      };
    },
  },
  mounted() {
    // Get dashboard settings
    this.getDashboardSettings();

    // Get Subscription products
    this.getSubscriptionProducts();

    // Set title
    this.setTitle();
  },
};
</script>

<style lang="scss">
@import "../../styles/lightboxes/SubscriptionExpiredLightbox";
</style>
